// メーカー取扱一覧画面
import {React, useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import japanLogo from '../image/japan.png'
import styled from 'styled-components';
import db from '../firebase';
import { collection, getDocs } from '@firebase/firestore';

function MakerHandlinglist(){
  return (
    <>
        <Breadcrumbs />
        <h1>メーカー取扱一覧画面</h1>
        {/* ↓データベースから会社名を動的に表示 */}
        <h2>オカムラ(動的にメーカー名表示)</h2>
        <Product/>
        {/* 商品1,2,3・・・・・ */}
    </>
  );
};

const Product = () =>{
  return(
    <ProductCss>
      <ProductRight />
    </ProductCss>
  )
}

function ProductRight() {
  const [okamura, setOkamura] = useState([]);

  useEffect(() =>{
    // データベースから取得する
    const postData = collection(db, 'okamura');
    getDocs(postData).then((snapshot)=>{
      console.log(snapshot.docs.map((doc) => doc.data()));
      setOkamura(snapshot.docs.map((doc) =>  doc.data()))
    })
    // console.log(postData)
  }, [])

  return(
    <ProductRightCss>
        {okamura.map((post, index)=>(
          <Flex>
            <ProductImage src={japanLogo} alt=''/>
            <div key={index}>
              <h3>{post.title}</h3>
              <p>{post.evaluation}</p>
              <p>{post.price}</p>
              <RightButtonCss>
                <button>この商品の口コミを見る</button>
                <button>この商品をAmazonで買う</button>
              </RightButtonCss>
            </div>
          </Flex>
        ))}
    </ProductRightCss>
  )
}

const breadcrumbs = [
  { text: 'ホーム', link: '/' },
  { text: 'メーカーから一覧検索', link: '/search/*' },
  { text: '商品詳細', link: '/category/product' },
];

const Breadcrumbs = () => (
  <nav aria-label="breadcrumb">
    <BreadcrumbOl>
      {breadcrumbs.map((breadcrumb, index) => (
        <li key={breadcrumb.link} className={`breadcrumb-item${index === breadcrumbs.length - 1 ? ' active' : ''}`}>
          {index === breadcrumbs.length - 1 ? (
            breadcrumb.text
          ) : (
            <Link to={breadcrumb.link}>{breadcrumb.text}</Link>
          )}
        </li>
      ))}
    </BreadcrumbOl>
  </nav>
);

const RightButtonCss = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ProductCss = styled.div`
  display: flex;
  margin: 0 20%;
`;

const ProductRightCss = styled.div`
  display: block;
`

const ProductImage = styled.img`
  min-height: 218px;
  max-width: 234px;
  width: auto;
  border-radius: 5%;
  padding: 30px;
`

const BreadcrumbOl = styled.ol`
  display: flex;
  justify-content: space-between;
  max-width: 400px
`;

const Flex = styled.div`
    display: flex;
    margin-bottom: 20px;
    background-color: #eee3cb;
`;

export default MakerHandlinglist;