//商品詳細の口コミ一覧
import React from 'react';

function ProductDetailReview() {
  return (
    <>
        <h1>商品詳細の口コミ一覧</h1>
    </>
  );
};

export default ProductDetailReview;