// メーカー取扱一覧画面
import {React} from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import CofoLogo from '../image/cofo.png';
// import db from '../firebase';
// import { collection, collectionGroup, getDocs } from '@firebase/firestore';

function MakerHandlinglistCofo(){
  return (
    <>
        <Breadcrumbs />
        <OkamuraImg src={CofoLogo} alt=''/>
        <h2>こちらの商品は入荷次第<br/>表示させていただきます。</h2>
        {/* <h1>メーカー取扱一覧画面</h1> */}
        {/* ↓データベースから会社名を動的に表示 */}
        {/* <h2>アイリスオーヤマ</h2> */}
        {/* <Product/> */}
        {/* 商品1,2,3・・・・・ */}
    </>
  );
};

// const Product = () =>{
//   return(
//     <ProductCss>
//       <ProductRight />
//     </ProductCss>
//   )
// }

// function ProductRight() {
//   const [cofo, setAirisuooyama] = useState([]);

//   useEffect(() =>{
//     // データベースから取得する
//     const postData = collection(db, 'airisuooyama');
//     getDocs(postData).then((snapshot)=>{
//       console.log(snapshot.docs.map((doc) => doc.data()));
//       setAirisuooyama(snapshot.docs.map((doc) =>  doc.data()))
//     })
//     // console.log(postData)
//   }, [])

//   return(
//     <ProductRightCss>
//         {cofo.map((post, index)=>(
//           <>
//             <Flex>
//               <ProductImage src={post.src} alt=''/>
//               <div key={index}>
//                 <Link to={post.ad} target="_blank" rel="noopener noreferrer"><h3>{post.title}</h3></Link>
//                 <Evolation>★ {post.evoluation}</Evolation>
//                 <Price>￥{post.price}</Price>
//               </div>
//             </Flex>
//             <RightButtonCss>
//               <button>この商品の口コミを見る</button>
//               <button><Link to={post.ad} target="_blank" rel="noopener noreferrer">この商品をAmazonで買う</Link></button>
//             </RightButtonCss>
//           </>
//         ))}
//     </ProductRightCss>
//   )
// }

const breadcrumbs = [
  { text: 'ホーム', link: '/' },
  { text: 'メーカーから一覧検索', link: '/search/*' },
  { text: 'Cofo', link: '/cofo' },
];

const Breadcrumbs = () => (
  <nav aria-label="breadcrumb">
    <BreadcrumbOl>
      {breadcrumbs.map((breadcrumb, index) => (
        <p key={breadcrumb.link} className={`breadcrumb-item${index === breadcrumbs.length - 1 ? ' active' : ''}`}>
          {index === breadcrumbs.length - 1 ? (
            breadcrumb.text
          ) : (
            <Link to={breadcrumb.link}>{breadcrumb.text}　＞　</Link>
          )}
        </p>
      ))}
    </BreadcrumbOl>
  </nav>
);

// const Evolation = styled.div`
//   color: yellow;
//   font-weight: 900;
//   -webkit-text-stroke: 1.5px black;
// `

// const Price = styled.div`
//   color: red;
//   font-weight: 1000;
// `

const OkamuraImg = styled.img`
  margin: 0 auto 20px;
  display: block;
  max-width: 300px;
`

// const RightButtonCss = styled.div`
//   display: flex;
//   justify-content: space-between;
//   // background-color: #eee3cb;
//   border-radius: 8%;
//   margin-bottom: 20px;
// `;

// const ProductCss = styled.div`
//   display: flex;
//   margin: 0 20%;
    
//   @media (max-width: 820px) {
//     margin: 0;
//   }
// `;

// const ProductRightCss = styled.div`
//   display: block;
//   margin-bottom: 20px;
//   border-radius: 3%;
// `

// const ProductImage = styled.img`
//   min-height: 218px;
//   max-width: 234px;
//   width: auto;
//   border-radius: 5%;
//   padding: 30px;
  
//   @media (max-width: 820px) {
//     padding: 20px;
//     max-width: 135px;
//   }
// `

const BreadcrumbOl = styled.div`
  display: flex;
  margin: 10px
  max-width: 400px
`;

// const Flex = styled.div`
//   display: flex;
//   // background-color: #eee3cb;
// `;

export default MakerHandlinglistCofo;