// 参考記事
import React from 'react';

function ReferenceArticle() {
  return (
    <>
        <h1>参考記事</h1>
    </>
  );
};

export default ReferenceArticle;