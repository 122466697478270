// メーカー取扱一覧画面
import {React, useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import db from '../firebase';
import SteelcaseLogo from '../image/steelcase.png';
import { collection, getDocs } from '@firebase/firestore';

function MakerHandlinglistSteelcase(){
  return (
    <>
        <Breadcrumbs />
        <OkamuraImg src={SteelcaseLogo} alt=''/>
        {/* <h1>メーカー取扱一覧画面</h1> */}
        {/* ↓データベースから会社名を動的に表示 */}
        {/* <h2>スチールケース</h2> */}
        <Product/>
        {/* 商品1,2,3・・・・・ */}
    </>
  );
};

const Product = () =>{
  return(
    <ProductCss>
      <ProductRight />
    </ProductCss>
  )
}

function ProductRight() {
  const [steelcase, setSteelcase] = useState([]);

  useEffect(() =>{
    // データベースから取得する
    const postData = collection(db, 'steelcase');
    getDocs(postData).then((snapshot)=>{
      console.log(snapshot.docs.map((doc) => doc.data()));
      setSteelcase(snapshot.docs.map((doc) =>  doc.data()))
    })
    // console.log(postData)
  }, [])

  return(
    <ProductRightCss>
        {steelcase.map((post, index)=>(
          <>
          <Flex>
            <ProductImage src={post.src} alt=''/>
            <div key={index}>
              <Link to={post.ad} target="_blank" rel="noopener noreferrer"><h3>{post.title}</h3></Link>
              <Evolation>★ {post.evaluation}</Evolation>
              <Price>￥{post.price}</Price>
            </div>
          </Flex>
          <RightButtonCss>
            <button><Link to={post.ad} target="_blank" rel="noopener noreferrer">この商品をAmazonで買う</Link></button>
          </RightButtonCss>
          </>
        ))}
    </ProductRightCss>
  )
}

const breadcrumbs = [
  { text: 'ホーム', link: '/' },
  { text: 'メーカーから一覧検索', link: '/search/*' },
  { text: 'スチールケース', link: '/steelcase' },
];

const Breadcrumbs = () => (
  <nav aria-label="breadcrumb">
    <BreadcrumbOl>
      {breadcrumbs.map((breadcrumb, index) => (
        <p key={breadcrumb.link} className={`breadcrumb-item${index === breadcrumbs.length - 1 ? ' active' : ''}`}>
          {index === breadcrumbs.length - 1 ? (
            breadcrumb.text
          ) : (
            <Link to={breadcrumb.link}>{breadcrumb.text}　＞　</Link>
          )}
        </p>
      ))}
    </BreadcrumbOl>
  </nav>
);

const Evolation = styled.div`
  color: yellow;
  font-weight: 900;
  -webkit-text-stroke: 1.5px black;
`

const Price = styled.div`
  color: red;
  font-weight: 1000;
`


const OkamuraImg = styled.img`
  margin: 0 auto 20px;
  display: block;
  max-width: 300px;
`

const RightButtonCss = styled.div`
  display: flex;
  justify-content: space-between;
  // background-color: #eee3cb;
  border-radius: 8%;
  margin-bottom: 20px;
`;

const ProductCss = styled.div`
  display: flex;
  margin: 0 20%;
    
  @media (max-width: 820px) {
    margin: 0;
  }
`;

const ProductRightCss = styled.div`
  display: block;
  margin-bottom: 20px;
  border-radius: 3%;
`

const ProductImage = styled.img`
  min-height: 218px;
  max-width: 234px;
  width: auto;
  border-radius: 5%;
  padding: 30px;
    
  @media (max-width: 820px) {
    padding: 20px;
    max-width: 135px;
  }
`

const BreadcrumbOl = styled.div`
  display: flex;
  margin: 10px;
  max-width: 400px
`;

const Flex = styled.div`
  display: flex;
  // margin-bottom: 20px;
  // background-color: #eee3cb;
  border-radius: 3%;
`;

export default MakerHandlinglistSteelcase;