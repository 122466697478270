import styled from 'styled-components';
import OkamuraLogo from '../image/okamura.png';
import ItokiLogo from '../image/itoki.png' ;
import KokuyoLogo from '../image/kokuyo.png';
import HamanLogo from '../image/hamannmira.png';
import HabadaLogo from '../image/Habada.png';
import CofoLogo from '../image/cofo.png';
import LowbackLogo from '../image/lowback.png';
import HighbackLogo from '../image/highback.png';
import MiddleBackLogo from '../image/middleBack.png';
import MessyuLogo from '../image/messyu.png';
import BackseatsetLogo from '../image/backseatset.png';
import ChairIcon from '@mui/icons-material/Chair';
import { Link } from 'react-router-dom';
import CatchphraseLogo from '../image/catchphrase.png';
// import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

function Home(){
  return(
    <AllBodyCss>
      <CatchphraseLogoCss src={CatchphraseLogo} alt=''/>
      <Link to='/search'><SubTitle name=' メーカー'/></Link>
      <h3><Link to={'/search'}><span>すべてを見る</span></Link></h3>
      <JapanMaker />
      <h3><Link to={'/search'}><span>すべてを見る</span></Link></h3>
      <ForeignMaker/>
      <Body/>
    </AllBodyCss>
  );
}

const Body = () =>{
  return(
    <BodyCss>
      <Left/>
    </BodyCss>
  )
}

const Left = () =>{
  return(
    <LeftCss>
      <Link to='/typelist'><SubTitle name='タイプ'/></Link>
      <Type/>
      {/*  */}
      {/* <Link to='/rankinglist'><SubTitle name='人気アクセスランキング'/></Link> */}
      {/* <Type/> */}
      <Link to='/pricerange'><SubTitle name='価格帯'/></Link>
      <Expensive/>
      {/* <Detail/> */}
    </LeftCss>
  );
}

// const Detail = () =>{
//   return(
//         <DetailCss>
//           <SubTitle name='掲載情報について'/>
//           <Link to='/contact'><SubTitle name='お問い合わせ'/></Link>
//           <Link to='/sitemap'><SubTitle name='サイトマップ'/></Link>
//         </DetailCss>
//   )
// }

const Expensive = () =>{
  return(
    <ExpensiveCss>
      <Link to={'/10000'}><p>～10,000円</p></Link>
      <Link to={'/10000_2'}><p>10,000円～20,000円</p></Link>
      <Link to={'/10000_3'}><p>20,000円～50,000円</p></Link>
      <Link to={'/10000_4'}><p>50,000円～100,000円</p></Link>
      <Link to={'/10000_5'}><p>100,000円～200,000円</p></Link>
      <Link to={'/10000_6'}><p>200,000円以上</p></Link>
    </ExpensiveCss>
  )
}

const Type = () =>{
  return(
    <>
    <Type5/>
    <Type4/>
    </>
  );
}

const Type5 = () =>{
  return(
    <TypeCss5>
      <Link to='/lawback'><Type5detail src={LowbackLogo} name='ローバック'/></Link>
      <Link to='/middleback'><Type5detail src={MiddleBackLogo} name='ミドルバック'/></Link>
      <Link to='/highback'><Type5detail src={HighbackLogo} name='ハイバック'/></Link>
    </TypeCss5>
  );
}

const Type4 = () =>{
  return(
    <TypeCss4>
      <Link to='/meshu'><Type4detail src={MessyuLogo} name='メッシュ素材' href=''/></Link>
      <Link to='/gaming'><Type4detail src={BackseatsetLogo} name='ゲーミングチェア' href=''/></Link>
    </TypeCss4>
  );
}

const Type5detail = (props) =>{
  return(
    <TypeHref>
      <Type5Image src={props.src} alt=''/>
      <Type5Span>{props.name}</Type5Span>
    </TypeHref>
  );
}

const Type4detail = (props) =>{
  return(
    <TypeHref>
      <Type4Image src={props.src} alt=''/>
      <Type5Span>{props.name}</Type5Span>
    </TypeHref>
  );
}

const SubTitle = (props) =>{
  return (
      <SubTitleCSS>
        <ChairIcon/>
        <SubTitleSpan>{props.name}</SubTitleSpan>
      </SubTitleCSS>
  )
}

const JapanMaker = () =>{
  return(
    <JapanMakerCss>
      {/* JapanTitle /> */}
      <Link to='/okamura'><Okamura src={OkamuraLogo} name='オカムラ'/></Link>
      <Link to='/kokuyo'><Kokuyo/></Link>
      <Link to='/itoki'><Itoki/></Link>
      {/* <ThreeLeaders/>
      <JapanButtonAll><Link to={'/search'}>すべてを見る</Link></JapanButtonAll> */}
    </JapanMakerCss>
  );
}

const ForeignMaker = () =>{
  return(
    <JapanMakerCss>
      {/* <ForeignTitle /> */}
      <Link to='/cofo'><Cofo src={CofoLogo} name='Cofo'/></Link>
      <Link to='/hermanmillar'><Herman src={HamanLogo} name='Haman'/></Link>
      <Link to='/habada'><Habada/></Link>
      {/* <ThreeLeaders/>
      <JapanButtonAll><Link to={'/search'}>すべてを見る</Link></JapanButtonAll> */}
    </JapanMakerCss>
  );
}

const Okamura = (props) =>{
  return(
    <OkamuraCss>
      <ImageOkamura src={props.src} alt=''/>
      <Okamuraspan>{props.name}</Okamuraspan>
    </OkamuraCss>
  );
}

const Cofo = (props) =>{
  return(
    <OkamuraCss>
      <ImageCofo src={props.src} alt=''/>
      <Okamuraspan>{props.name}</Okamuraspan>
    </OkamuraCss>
  );
}
const Herman = (props) =>{
  return(
    <OkamuraCss>
      <ImageHerman src={props.src} alt=''/>
      <Okamuraspan>{props.name}</Okamuraspan>
    </OkamuraCss>
  );
}

const Itoki = () =>{
  return(
    <ItokiCss>
      <Image202 src={ItokiLogo} alt=''/>
      <Itokispan>イトーキ</Itokispan>
    </ItokiCss>
  );
}

const Habada = () =>{
  return(
    <OkamuraCss>
      <HabadaImage src={HabadaLogo} alt=''/>
      <Okamuraspan>Habada</Okamuraspan>
    </OkamuraCss>
  );
}

const Kokuyo = () =>{
  return(
    <KokuyoCss>
      <ImageOkamura src={KokuyoLogo} alt=''/>
      <Okamuraspan>コクヨ</Okamuraspan>
    </KokuyoCss>
  );
}

const CatchphraseLogoCss = styled.img`
  margin: 50px 20%;
  @media (max-width: 820px) {
    max-width: 100%;
    height: auto;
  margin: 50px auto;
  }
`

const SubTitleSpan = styled.span`
  font-size: 20px;
  font-weight: 700;
  font-family: serif;
`

const AllBodyCss = styled.div`
  margin: 0 20%;

  @media (max-width: 820px) {
    margin: auto;
    font-size: 8px;
  }
`;

const BodyCss = styled.div`
  display: flex;
`;

const LeftCss = styled.div`
  display: block;
  width: 100%;
`;

const ExpensiveCss = styled.div`
  display: block;
  background: #f8f8f8;
  border-radius: 3%;
  font-size: 17px;
  font-family: serif;
  padding: 5% 20%;
  margin: 20px 0;
`;

const TypeHref = styled.div`
  display: block;
  text-align: center;
  margin: auto;
  font-size: 17px;
  font-family: serif;
`;

const TypeCss5 = styled.div`
  display: flex;
  justify-content: space-between;
  margin 0 10% 3%;
    
  @media (max-width: 820px) {
    margin: 20px 0px;
  }
`;

const TypeCss4 = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 18%;
  
  @media (max-width: 820px) {
    margin: 20px 5%
  }
`;

const Type5Image = styled.img`
  max-height: 200px;
  display: block;
  width: auto; 
  margin: auto;
  padding: 20px;
  border-radius: 10%;
  background-color: #f8f8f8;
  margin: 10px;

  @media (max-width: 820px) {
    max-height: 100px;
  }
`;

const Type4Image = styled.img`
  height: 100%;
  display: block;
  width: auto; 
  margin: auto;
  padding: 20px;
  border-radius: 10%;
  background-color: #f8f8f8;
  margin: 10px;

  @media (max-width: 820px) {
    max-height: 100px;
  }
`;

const Type5Span = styled.span`
  bottom: 1%;
`;

const SubTitleCSS = styled.div`
  display: flex;
  margin: 10px 0;
  align-items: center;
`;

const Image2 = styled.img`
  text-align: center;
  padding: 10px;
  text-align: center;
  background-color: #f8f8f8;
  margin: 0 5px;
  border-radius: 5%;

  @media (max-width: 820px) {
    max-height: 15px;
  }
`;

const ImageOkamura = styled(Image2)`
  @media (max-width: 820px) {
    max-height: 15px;
    padding: 17px 10px;
  }
`;

const ImageCofo = styled(Image2)`
  @media (max-width: 820px) {
    max-height: 28px;
    padding: 17px 10px;
  }
`;

const ImageHerman = styled(Image2)`
  @media (max-width: 820px) {
    max-height: 25px;
    padding: 18px 5px;
  }
`;

const Image202 = styled(Image2)`
  @media (max-width: 820px) {
    max-height: 29px;
  }
`;

const HabadaImage = styled.img`
  max-height: 54px;
  text-align: center;
  padding: 10px;
  background-color: #f8f8f8;
  margin: 0 5px;
  border-radius: 5%;

  @media (max-width: 820px) {
    max-height: 22px;
    padding: 20px 10px;
  }
`;

const JapanMakerCss = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 820px) {
    margin: 20px 0;
  }
`;

const OkamuraCss = styled.div`
  display: block;
  text-align: -webkit-center;
`;

const ItokiCss = styled.div`
  margin-top: -20px;
  display: block;
  text-align: -webkit-center;

  @media (max-width: 820px) {
    margin: 0 auto
  }
`;

const KokuyoCss = styled.div`
  display: block;
  text-align: -webkit-center;
`;

const Okamuraspan = styled.span`
  text-align: center;
  display: block;
  margin-top: 8%;
  font-size: 20px;
  font-family: serif;
`;

const Itokispan = styled.span`
  text-align: center;
  display: block;
  margin-top: 10px;
  font-size: 20px;
  font-family: serif;
`;


export default Home;
