import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

function SiteMap() {
  return (
    <SiteMapCss>
      <Breadcrumbs />
      <h1>サイトマップ</h1>
      <ContactBlock />
    </SiteMapCss>
  );
};

const breadcrumbs = [
  { text: 'ホーム', link: '/' },
];

const Breadcrumbs = () => (
  <nav aria-label="breadcrumb">
    <ol className="breadcrumb">
      {breadcrumbs.map((breadcrumb, index) => (
        <li key={breadcrumb.link} className={`breadcrumb-item${index === breadcrumbs.length - 1 ? ' active' : ''}`}>
          {index === breadcrumbs.length ? (
            breadcrumb.text
          ) : (
            <Link to={breadcrumb.link}>{breadcrumb.text}</Link>
          )}
        </li>
      ))}
    </ol>
  </nav>
);

const ContactBlock =()=>{
  return(
      <ContactBlockCss>
          <Left/>
          <Right/>
      </ContactBlockCss>
  )
}

const Left = () =>{
  return(
      <LeftCss>
      <h3>オフィスチェアを探す</h3>
      </LeftCss>
  );
}

const Right = () =>{
  return(
      <>
      <h3>口コミトップ</h3>
      </>
  );
}

const SiteMapCss = styled.div`
  max-width: 980px;
  margin: 0 auto;
`;


const ContactBlockCss = styled.div`
  display: flex;
`;

const LeftCss = styled.div`
  width: 33.333%
`;

export default SiteMap;