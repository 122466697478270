// メーカー取扱一覧画面
import {React, useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import db from '../firebase';
import { collection, getDocs } from '@firebase/firestore';

function TypelistHighback(){
  return (
    <>
        <Breadcrumbs />
        {/* <h1>タイプ別一覧画面</h1> */}
        {/* ↓データベースから会社名を動的に表示 */}
        {/* <h2>ゲーミングチェア</h2> */}
        <Product/>
        {/* 商品1,2,3・・・・・ */}
    </>
  );
};

const Product = () =>{
  return(
    <ProductCss>
      <ProductRight />
    </ProductCss>
  )
}

function ProductRight() {
  const [highback, setHighback] = useState([]);

  useEffect(() =>{
    // データベースから取得する
    const postData = collection(db, 'highback');
    getDocs(postData).then((snapshot)=>{
      console.log(snapshot.docs.map((doc) => doc.data()));
      setHighback(snapshot.docs.map((doc) =>  doc.data()))
    })
    // console.log(postData)
  }, [])

  return(
    <ProductRightCss>
        {highback.map((post, index)=>(
          <>
            <Flex>
              <ProductImage src={post.src} alt=''/>
              <div key={index}>
                <Link to={post.ad} target="_blank" rel="noopener noreferrer"><Hignbackh3>{post.title}</Hignbackh3></Link>
                <Evolation>★ {post.evolation}</Evolation>
                <Price>￥{post.price}</Price>
              </div>
            </Flex>
            <RightButtonCss>
              <button><Link to={post.ad} target="_blank" rel="noopener noreferrer">この商品をAmazonで買う</Link></button>
            </RightButtonCss>
          </>
        ))}
    </ProductRightCss>
  )
}

const Breadcrumbs = () => (
  <nav aria-label="breadcrumb">
    <BreadcrumbOl>
      <Link to='/'><p>ホーム</p></Link>
      <p>　＞　</p>
      <Link to='/highback'><p>ハイバック</p></Link>
    </BreadcrumbOl>
  </nav>
);

const Hignbackh3 = styled.h3`
@media (max-width: 820px) {
  max-width: 200px;
}
`

const Evolation = styled.div`
  color: yellow;
  font-weight: 900;
  -webkit-text-stroke: 1.5px black;
`

const Price = styled.div`
  color: red;
  font-weight: 1000;
`

const RightButtonCss = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 8%;
  margin-bottom: 20px;
`;

const ProductCss = styled.div`
  display: flex;
  margin: 0 20%;
    
  @media (max-width: 820px) {
    margin: 0;
  }
`;

const ProductRightCss = styled.div`
  display: block;
`

const ProductImage = styled.img`
  min-height: 218px;
  max-width: 234px;
  width: auto;
  border-radius: 5%;
  padding: 30px;
    
  @media (max-width: 820px) {
    padding: 20px;
    max-width: 135px;
  }
`

const BreadcrumbOl = styled.div`
  font-size: 16px;
  margin: 20px;
  display: flex;
  // justify-content: space-between;
  max-width: 400px
`;

const Flex = styled.div`
    display: flex;
    // margin-bottom: 20px;
    // background-color: #eee3cb;
    border-radius: 3%;
`;

export default TypelistHighback;