import React from 'react';
import styled from 'styled-components';
import JapanLogo from '../image/japan.png';
import SwisuLogo from '../image/swisu.png';
import USALogo from '../image/usa.png';
import DenmarkLogo from '../image/denmark.png';
import TaiwanLogo from '../image/taiwan.png';
import { Link } from 'react-router-dom';

function Search(){
  return (
    <SearchCss>
        <Breadcrumbs />
        {/* <h1>ブランド一覧</h1> */}
        <JapanMaker />
        <SuiceMaker />
        <USAMaker />
        <DenmarkMaker />
        <ChainaMaker />
    </SearchCss>
  );
};

const Breadcrumbs = () => (
    <nav aria-label="breadcrumb">
        <BreadcrumbOl>
            <Link to='/'><p>ホーム</p></Link>
            <p>　＞　</p>
            <Link to='/search/*'><p>ブランド一覧検索</p></Link>
        </BreadcrumbOl>
    </nav>
);

const JapanMaker = () =>{
    return(
        <>
            <Maker name='日本' src={JapanLogo}/>
            <MakerCss>
                <CheckBoxRawJ1 />
                <CheckBoxRawJ2 />
                <CheckBoxRawJ3 />
            </MakerCss>
        </>
    );
}

const SuiceMaker = () =>{
    return(
        <>
            <Maker name='スイス' src={SwisuLogo}/>
            <MakerCss>
                <CheckBoxRawSW/>
            </MakerCss>
        </>
    );
}

const USAMaker = () =>{
    return(
        <>
            <Maker name='アメリカ' src={USALogo}/>
            <MakerCss>
                <CheckBoxRawUS1/>
            </MakerCss>
        </>
    );
}

const DenmarkMaker = () =>{
    return(
        <>        
            <Maker name='デンマーク'  src={DenmarkLogo}/>
            <MakerCss>
                <CheckBoxRawDM1 />
            </MakerCss>
        </>

    );
}

const ChainaMaker = () =>{
    return(
        <>
            <Maker name='台湾' src={TaiwanLogo}/>
            <MakerCss>
                <CheckBoxRawTW />
            </MakerCss>
        </>
    );
}
const CheckBoxRawJ1 = () =>{
    return(
        <CheckBoxRawCss>
            <Link to={'/itoki'}><CheckBox name={'イトーキ'}/></Link>
            <Link to={'/okamura'}><CheckBox name={'オカムラ'}/></Link>
            <Link to={'/kokuyo'}><CheckBox name={'コクヨ'}/></Link>
        </CheckBoxRawCss>
    )
}

const CheckBoxRawJ2 = () =>{
    return(
        <CheckBoxRawCss>
            <Link to={'/airisuooyama'}><CheckBox name={'アイリスオーヤマ'}/></Link>
            <Link to={'/takasho'}><CheckBox name={'タカショー'}/></Link>
            <Link to={'/nohritu'}><CheckBox name={'ノーリツ'}/></Link>
        </CheckBoxRawCss>
    )
}

const CheckBoxRawJ3 = () =>{
    return(
        <CheckBoxRawCss>
            <Link to={'/yamasoro'}><CheckBox name={'ヤマソロ'}/></Link>
            
        </CheckBoxRawCss>
    )
}

const CheckBoxRawSW = () =>{
    return(
        <CheckBoxRawCss>
            <Link to={'/vitra'}><CheckBox name={'ヴィトラ(Vitra)'}/></Link>
        </CheckBoxRawCss>
    )
}

const CheckBoxRawUS1 = () =>{
    return(
        <CheckBoxRawCss>
            <Link to={'/hermanmillar'}><CheckBox name={'ハーマンミラー'}/></Link>
            <Link to={'/steelcase'}><CheckBox name={'スチールケース'}/></Link>
        </CheckBoxRawCss>
    )
}

const CheckBoxRawDM1 = () =>{
    return(
        <CheckBoxRawCss>
            <Link to={'/hurituhansen'}><CheckBox name={'フリッツ・ハンセン'}/></Link>
            <Link to={'/carlhansen'}><CheckBox name={'カール・ハンセン・アンド・サン'}/></Link>
        </CheckBoxRawCss>
    )
}

const CheckBoxRawTW = () =>{
    return(
        <CheckBoxRawCss>
            <Link to={'/habada'}><CheckBox name={'Habada'}/></Link>
        </CheckBoxRawCss>
    )
}

const CheckBox = (props) =>{
    return(
        <CheckBoxCss>
            <input type="checkbox" id="scales" name="scales" />
            <label for="scales">{props.name}</label>
        </CheckBoxCss>
    )
}

const Maker = (props) =>{
    return(
        <MakerLanguageCSS>
            <img src={props.src} alt="" />
            <h2>{props.name}</h2>
        </MakerLanguageCSS>
    );
}

const BreadcrumbOl = styled.div`
  font-size: 16px;
  margin: 20px;
  display: flex;
  // justify-content: space-between;
  max-width: 400px
`;

const CheckBoxRawCss = styled.div`
    max-width: 345px;

    @media (max-width: 820px) {
        max-width: 245px;
    }
`;

const CheckBoxCss = styled.div`
    display: grid;
    grid-template-columns: 30px auto;
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 20px;
`;

const SearchCss = styled.div`
    max-width: 980px;
    margin: 0 auto;
`;

const MakerCss = styled.div`
padding-top: 20px;
display: flex;
padding-bottom: 20px;
margin-bottom: 32px;
// background: rgb(238, 227, 203);
border-bottom: 4px solid #dedcd7;
`;

const MakerLanguageCSS = styled.div`
display: flex;
align-items: center;
// background: rgb(238, 227, 203);
border-bottom: 4px solid #dedcd7;

`;

export default Search;