// タイプ別一覧
import React from 'react';
import { Link } from 'react-router-dom';

function TypeList() {
  return (
    <>
        <Breadcrumbs />
        <h1>タイプ別一覧</h1>
    </>
  );
};

const breadcrumbs = [
  { text: 'ホーム', link: '/' },
];

const Breadcrumbs = () => (
  <nav aria-label="breadcrumb">
    <ol className="breadcrumb">
      {breadcrumbs.map((breadcrumb, index) => (
        <li key={breadcrumb.link} className={`breadcrumb-item${index === breadcrumbs.length - 1 ? ' active' : ''}`}>
          {index === breadcrumbs.length ? (
            breadcrumb.text
          ) : (
            <Link to={breadcrumb.link}>{breadcrumb.text}</Link>
          )}
        </li>
      ))}
    </ol>
  </nav>
);

export default TypeList;