import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

function Contact(){
  return (
    <>
        <Breadcrumbs />
        <Search/>
        <ContactBody />
    </>
  );
};

const breadcrumbs = [
  { text: 'ホーム', link: '/' },
];

const Breadcrumbs = () => (
  <nav aria-label="breadcrumb">
    <ol className="breadcrumb">
      {breadcrumbs.map((breadcrumb, index) => (
        <li key={breadcrumb.link} className={`breadcrumb-item${index === breadcrumbs.length - 1 ? ' active' : ''}`}>
          {index === breadcrumbs.length ? (
            breadcrumb.text
          ) : (
            <Link to={breadcrumb.link}>{breadcrumb.text}</Link>
          )}
        </li>
      ))}
    </ol>
  </nav>
);

const ContactBody = () =>{
    return(
        <ContactBodyCss>
            <h1>ヘルプページ</h1>
            <h2>お知らせ</h2>
            <p>2023年3月11日<span>bbbbbbbbbbbb</span></p>
            <p>2023年3月10日<span>aaaaaaaaaaaa</span></p>
            <ContactBlock/>
        </ContactBodyCss>
    )
}

const ContactBlock =()=>{
    return(
        <ContactBlockCss>
            <Left/>
            <Right/>
        </ContactBlockCss>
    )
}

const Left = () =>{
    return(
        <LeftCss>
        <h3>会員登録・ログインについて</h3>
        </LeftCss>
    );
}

const Right = () =>{
    return(
        <>
        <h3>よくあるご質問について</h3>
        </>
    );
}

const Search = () => {
    return (
      <SearchContainer>
        <Input type="text" placeholder="Search..." />
        <Button>検索</Button>
      </SearchContainer>
    );
  };

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Input = styled.input`
  padding: 8px;
  border: 2px solid black;
  border-radius: 4px;
  margin-right: 8px;
`;

const Button = styled.button`
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const ContactBlockCss = styled.div`
  display: flex;
`;

const ContactBodyCss = styled.div`
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
`;

const LeftCss = styled.div`
  width: 33.333%
`;

export default Contact;