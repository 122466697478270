import {initializeApp} from 'firebase/app';
import {getFirestore} from 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyAQqCg6uBQYWzSUZ0-Cf1zQmtKaEBhnibM",
    authDomain: "oisusan-react.firebaseapp.com",
    projectId: "oisusan-react",
    storageBucket: "oisusan-react.appspot.com",
    messagingSenderId: "252325569967",
    appId: "1:252325569967:web:693e969c572627d50ffb5a",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db;