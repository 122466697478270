// ランキングリスト
import React from 'react';

function RankingList(){
  return (
    <>
        <h1>ランキングリスト</h1>
    </>
  );
};

export default RankingList;