import styled from 'styled-components';
import Home from './components/Home';
import Search from './components/Search';
import Contact from './components/Contact';
import MakerHandlinglist from './components/MakerHandlinglist';
import PriceRangeList from './components/PriceRangeList';
import ProductDetailReview from './components/ProductDetailReview';
import ProductDetails from './components/ProductDetails';
import RankingList from './components/RankingList';
import ReferenceArticle from './components/ReferenceArticle';
import SiteMap from './components/SiteMap';
import TypeList from './components/TypeList';
import { Route, Routes, Link } from 'react-router-dom';
import MakerHandlinglistOkamura from './components/MakerHandlinglistOkamura';
import MakerHandlinglistSteelcase from './components/MakerHandlinglistSteelcase';
import MakerHandlinglistTakasho from './components/MakerHandlinglistTakasho';
import MakerHandlinglistNohritu from './components/MakerHandlinglistNohritu';
import MakerHandlinglistYamasoro from './components/MakerHandlinglistYamasoro';
import MakerHandlinglistAirisuooyama from './components/MakerHandlinglistAirisuooyama';
import MakerHandlinglistItoki from './components/MakerHandlinglistItoki';
import MakerHandlinglistKokuyo from './components/MakerHandlinglistKokuyo';
import MakerHandlinglistVitra from './components/MakerHandlinglistVitra';
import MakerHandlinglistHabada from './components/MakerHandlinglistHabada';
import MakerHandlinglistCarlhansen from './components/MakerHandlinglistCarlhansen';
import MakerHandlinglistHurituhansen from './components/MakerHandlinglistHurituhansen';
import MakerHandlinglistHermanMillar from './components/MakerHandlinglistHermanMillar';
import PriceRangeListOneman from './components/PriceRangeList_1man';
import PriceRangeListOneTwoman from './components/PriceRangeList_1_2man';
import PriceRangeListTwoFiveman from './components/PriceRangeList_2_5man';
import PriceRangeListFiveTenman from './components/PriceRangeList_5_10man';
import PriceRangeListTenTwentyman from './components/PriceRangeList_10_20man';
import PriceRangeListTwentyman from './components/PriceRangeList_20man';
import TypelistLawback from './components/TypelistLawback';
import TypelistMeshu from './components/TypelistMeshu';
import TypelistMidlleback from './components/TypelistMidlleback';
import TypelistHighback from './components/TypelistHighback';
import TypelistGaming from './components/TypelistGaming';
import Samne from './image/headerlogo.png'
import './App.css'
import MakerHandlinglistCofo from './components/MakerHandlinglistCofo';

function App() {
  return (
    <AppCss>
      <Header>
      <Link to='/'><HeaderIcon src={Samne} alt=''/></Link>
      </Header>
      <Routes>
        <Route path="/" element={<HomeIndex />} />
        <Route path="/search/*" element={<SearchIndex />} />
        <Route path="/contact" element={<ContactIndex />} />
        <Route path="/makerlist" element={<MakerHandlinglistIndex />} />
        <Route path="/pricerange" element={<PriceRangeListIndex />} />
        <Route path="/productdetailreview" element={<ProductDetailReviewIndex />} />
        <Route path="/productdetail" element={<ProductDetailsIndex />} />
        <Route path="/rankinglist" element={<RankingListIndex />} />
        <Route path="/referencearticle" element={<ReferenceArticleIndex />} />
        <Route path="/sitemap" element={<SiteMapIndex />} />
        <Route path="/typelist" element={<TypeListIndex />} />
        <Route path='/1' element={<MakerHandlinglist />}></Route>
        <Route path='/okamura' element={<MakerHandlinglistOkamura />}></Route>
        <Route path='/steelcase' element={<MakerHandlinglistSteelcase />}></Route>
        <Route path='/takasho' element={<MakerHandlinglistTakasho />}></Route>
        <Route path='/nohritu' element={<MakerHandlinglistNohritu />}></Route>
        <Route path='/yamasoro' element={<MakerHandlinglistYamasoro />}></Route>
        <Route path='/airisuooyama' element={<MakerHandlinglistAirisuooyama />}></Route>
        <Route path='/kokuyo' element={<MakerHandlinglistKokuyo />}></Route>
        <Route path='/itoki' element={<MakerHandlinglistItoki />}></Route>
        <Route path='/vitra' element={<MakerHandlinglistVitra />}></Route>
        <Route path='/carlhansen' element={<MakerHandlinglistCarlhansen />}></Route>
        <Route path='/hurituhansen' element={<MakerHandlinglistHurituhansen />}></Route>
        <Route path='/habada' element={<MakerHandlinglistHabada />}></Route>
        <Route path='/hermanmillar' element={<MakerHandlinglistHermanMillar />}></Route>
        <Route path='/10000' element={<PriceRangeListOneman />}></Route>
        <Route path='/10000_2' element={<PriceRangeListOneTwoman />}></Route>
        <Route path='/10000_3' element={<PriceRangeListTwoFiveman />}></Route>
        <Route path='/10000_4' element={<PriceRangeListFiveTenman />}></Route>
        <Route path='/10000_5' element={<PriceRangeListTenTwentyman />}></Route>
        <Route path='/10000_6' element={<PriceRangeListTwentyman />}></Route>
        <Route path='/meshu' element={<TypelistMeshu />}></Route>
        <Route path='/highback' element={<TypelistHighback />}></Route>
        <Route path='/middleback' element={<TypelistMidlleback />}></Route>
        <Route path='/lawback' element={<TypelistLawback />}></Route>
        <Route path='/gaming' element={<TypelistGaming />}></Route>
        <Route path='/cofo' element={<MakerHandlinglistCofo />}></Route>
      </Routes>
      <TopA href="#">ページトップへ戻る</TopA>
      <footer>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          当サイトは500件以上の椅子を取り扱っております。
          お気軽にご覧ください。
          お問い合わせはこちらまで。<br />
          yonamasahiro@gmail.com
        </li>
      </ul>
      </footer>
    </AppCss>
  );
}

function HomeIndex() {
  return <Home />;
}

function SearchIndex() {
  return <Search />;
}

function ContactIndex() {
  return <Contact />;
}

function MakerHandlinglistIndex() {
  return <MakerHandlinglist />;
}

function PriceRangeListIndex() {
  return <PriceRangeList />;
}

function ProductDetailReviewIndex() {
  return <ProductDetailReview />;
}

function ProductDetailsIndex() {
  return <ProductDetails />;
}

function RankingListIndex() {
  return <RankingList />;
}

function ReferenceArticleIndex() {
  return <ReferenceArticle />;
}

function SiteMapIndex() {
  return <SiteMap />;
}

function TypeListIndex() {
  return <TypeList />;
}

const TopA = styled.a`
position: fixed;
bottom: 10px;
right: 10px;
font-weight: bold;
padding: 0.7em;
text-align: center;
background: rgb(255, 0, 0);
color: #fff;
transition: 0.3s0;
background-image: linear-gradient(144deg,#AF40FF, #5B42F3 50%,#00DDEB);
border-radius: 40%;
`

const HeaderIcon = styled.img`
  margin-left: 20px;
  width: 150px;
  height: 40px;
`;

const Header = styled.header`
  padding-top: 20px;
  min-height: 60px;
  border-bottom: 7px solid #7691A3;
  // background: #eee3cb;

  @media (max-width: 820px) {
    max-width: 820px;
  }
`;

const AppCss = styled.div`
  margin: auto;
  // background-color: #cfc2aa;
`


export default App;
